<template>
  <v-dialog v-model="modalData.dialog" max-width="1150px" persistent scrollable>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline" @click="getOrders">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- <pre>
          {{ formModel }}
        </pre> -->
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.webshop && statuses.webshop.order"
                v-model="formModel.status"
                :items="statuses.webshop.order"
                label="Status"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("ORDER_STATUSES." + slotProps.item.key) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("ORDER_STATUSES." + slotProps.item.key)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel && formModel.custom_fields"
                v-model="formModel.custom_fields.surname"
                :label="$t('FORMS.surname')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel && formModel.custom_fields"
                v-model="formModel.custom_fields.christian_name1"
                :label="$t('FORMS.name')"
                :rules="nameRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel && formModel.custom_fields"
                v-model="formModel.custom_fields.ordered_at"
                :label="$t('FORM_INPUT_NAMES.ordered_at')"
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel && formModel.custom_fields"
                v-model="formModel.custom_fields.email"
                label="Email"
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel.custom_fields"
                v-model="formModel.custom_fields.phone"
                :label="$t('TABLE.phone')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel.custom_fields"
                v-model="formModel.custom_fields.companyName"
                :label="$t('TABLE.company')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="formModel.custom_fields && formModel.custom_fields.products"
            class="mb-5"
          >
            <v-col cols="12">
              <v-data-table
                :headers="productHeaders"
                :items="formModel.custom_fields.products"
                class="elevation-1"
                :hide-default-footer="true"
              >
                <template v-slot:item.price="{ item }">
                  <span>
                    {{ item.price }}
                    {{ formModel.custom_fields.currency }}
                  </span>
                </template>
                <template v-slot:item.price="{ item }">
                  <span>
                    {{ item.price }}
                    {{ formModel.custom_fields.currency }}
                  </span>
                </template>
                <template v-slot:item.computedPrice="{ item }">
                  <span>
                    {{ item.computedPrice }}
                    {{ formModel.custom_fields.currency }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <h6
                v-if="formModel.custom_fields && formModel.custom_fields.coupon"
              >
                Kuponkedvezmény:
                {{ formModel.custom_fields.coupon.discount_amount }}
                {{ formModel.custom_fields.currency }}
              </h6>
              <h6
                v-if="
                  formModel.custom_fields &&
                  formModel.custom_fields.shipping_price
                "
              >
                Futárszolgálattal (előre utalással):
                {{ formModel.custom_fields.shipping_price }}
                {{ formModel.custom_fields.currency }}
              </h6>
              <h6
                v-if="formModel.custom_fields && formModel.custom_fields.amount"
              >
                Összesen:
                {{ formModel.custom_fields.amount }}
                {{ formModel.custom_fields.currency }}
              </h6>
            </v-col>
          </v-row>
          <v-row
            v-if="
              formModel &&
              formModel.custom_fields &&
              formModel.custom_fields.shipping_address
            "
            class="mt-7"
          >
            <v-col cols="12" sm="6" md="6">
              {{ $t("WEBSHOP.shipping_address") }}
              <v-text-field
                v-model="formModel.custom_fields.shipping_address.country"
                :label="$t('FORMS.country')"
                disabled="disabled"
              ></v-text-field>

              <v-text-field
                v-model="formModel.custom_fields.shipping_address.zip"
                :label="$t('FORM_INPUT_NAMES.zip')"
              ></v-text-field>

              <v-text-field
                v-model="formModel.custom_fields.shipping_address.city"
                :label="$t('FORM_INPUT_NAMES.city')"
              ></v-text-field>
              <v-text-field
                v-model="formModel.custom_fields.shipping_address.address"
                :label="$t('FORM_INPUT_NAMES.address')"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="6"
              v-if="
                formModel &&
                formModel.custom_fields &&
                formModel.custom_fields.invoice_address
              "
            >
              {{ $t("WEBSHOP.billing_address") }}
              <v-text-field
                v-if="formModel.custom_fields.invoice_address.company_name"
                v-model="formModel.custom_fields.invoice_address.company_name"
                :label="$t('FORM_INPUT_NAMES.company_name')"
              ></v-text-field>

              <v-text-field
                v-if="formModel.custom_fields.invoice_address.company_name"
                v-model="formModel.custom_fields.invoice_address.tax_number"
                :label="$t('FORM_INPUT_NAMES.company_tax_number')"
              ></v-text-field>

              <v-text-field
                v-if="
                  formModel.custom_fields.invoice_address.company_name &&
                  formModel.custom_fields.invoice_address.resellerLicence
                "
                v-model="
                  formModel.custom_fields.invoice_address.resellerLicence
                "
                :label="$t('FORM_INPUT_NAMES.resellerLicence')"
              ></v-text-field>

              <v-text-field
                v-if="!formModel.custom_fields.invoice_address.company_name"
                v-model="formModel.custom_fields.invoice_address.surname"
                :label="$t('FORM_INPUT_NAMES.surname')"
              ></v-text-field>
              <v-text-field
                v-if="!formModel.custom_fields.invoice_address.company_name"
                v-model="formModel.custom_fields.invoice_address.name"
                :label="$t('FORM_INPUT_NAMES.name')"
              ></v-text-field>

              <v-text-field
                v-model="formModel.custom_fields.invoice_address.country"
                :label="$t('FORMS.country')"
                disabled="disabled"
              ></v-text-field>

              <v-text-field
                v-model="formModel.custom_fields.invoice_address.zip"
                :label="$t('FORM_INPUT_NAMES.zip')"
              ></v-text-field>

              <v-text-field
                v-model="formModel.custom_fields.invoice_address.city"
                :label="$t('FORM_INPUT_NAMES.city')"
              ></v-text-field>
              <v-text-field
                v-model="formModel.custom_fields.invoice_address.address"
                :label="$t('FORM_INPUT_NAMES.address')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="formModel.custom_fields">
              <b>{{ formModel.custom_fields.message }}</b>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mt-5"
                outlined
                :label="$t('FORM_INPUT_NAMES.notes')"
                v-model="formModel.custom_fields.comment"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Orders";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
// import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";

const TRANSLATED_ATRIBUTES = [];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [];

export const initialFormData = () => ({
  id: null,
  status: 1,
  christian_name1: null,
  christian_name2: null,
  surname: null,

  translations: {},
  products: [],
  custom_fields: { comment: "" },
});

export default {
  name: "PageForm",
  props: [
    "modalData",
    "permissions",
    "statuses",
    "endPoint",
    "orderCollection",
  ],
  // components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins, SnackBarInfoComponent],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,

      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      productHeaders: [
        {
          text: this.$t("FORM_INPUT_NAMES.product_name"),
          // align: 'start',
          // sortable: false,
          value: "name",
        },
        { text: "Ár", value: "price" },
        { text: "db", value: "quantity" },
        { text: "Összesen", value: "computedPrice" },
      ],

      previewable_mime_types: [
        "image/png",
        "image/jpeg",
        "image/x-ms-bmp",
        "image/webp",
        "image/gif",
        "image/svg+xml",
      ],
      files: [],
    };
  },
  computed: {
    // ...mapGetters(["productCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id &&
        this.formModel.custom_fields &&
        this.formModel.custom_fields.amount
        ? this.$t("FORMS.edit") +
            this.formModel.order_number +
            "; Összeg:  " +
            this.formModel.custom_fields.amount +
            " " +
            this.formModel.custom_fields.currency
        : this.$t("MENU.NEW") + " " + this.$t("WEBSHOP.order");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },

    "formModel.products": function (newVal) {
      this.files = this.calculateFiles(newVal);
    },
  },
  methods: {
    // ...mapActions(["fetchProduct"]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );
      model.products = this.files.map((file) => file.id);
      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm", data);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    calculateFiles() {
      if (!this.formModel.products) return [];

      let f = [];
      let product;
      this.formModel.products.forEach((id) => {
        product = this.productCollection.find((product) => product.id == id);

        product.productName =
          product.translations[this.selectedLocale.lang].name;

        let noImage = {
          name: "no_image.png",
          path: "img/no_image.png",
          size: 1,
          user: "",
          mime_type: "image/jpeg",
          created_at: "2022-01-19 13:27:07",
        };
        if (
          !product.translations[this.selectedLocale.lang].custom_fields.image ||
          !product.translations[this.selectedLocale.lang].custom_fields.image[0]
        ) {
          product.file = noImage;
        } else {
          product.file =
            product.translations[
              this.selectedLocale.lang
            ].custom_fields.image[0];
        }

        f.push(product);
      });

      return f;
    },

    getFile(file) {
      return (
        process.env.VUE_APP_BACKEND_URL.replace(/\/+$/, "") + "/" + file.path
      );
    },

    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },

    getOrders() {
      let requestData = {
        from: "2022-08-08 00:33:47",
        to: "2022-10-08 23:33:47",
        key: "591d3d5a-773c-482e-a2e3-fd73abe17ecf",
      };
      ApiService.post("1/order/getOrders", requestData)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },

  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
